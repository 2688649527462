import React, { useContext, useEffect } from "react";

// import profile from "../../assets/Einstein.png";
import Container from "../../components/ui/Container";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";

import icon1 from "../../assets/time.svg";
import icon2 from "../../assets/repeat.svg";
import icon3 from "../../assets/book.svg";
import ScrollingContext from "../../context/ScrollingContext";
import { getPartialPercentage, getScrollingPercentage } from "../../services/scrollingServices";
import AuthContext from "../../context/AuthContext";

import bg from "../../assets/join-bg1.png";
import "./AboutSection.css";
import Button from "../../components/ui/Button";
import Waves from "../../components/ui/Waves";

const AboutSection = () => {
    const { token } = useContext(AuthContext);
    return (
        <>
            <section className="join-us__section bg-slate-900">
                <div
                    className="bg-overlay clr-white"
                    style={{
                        // background: `linear-gradient(linear-gradient(45deg, #0947db, #07d89d00)), url(${bg})`,
                        background: ` linear-gradient(
                                50deg,
                                rgba(38, 44, 53, 0.5) 0%,
                                rgba(38, 44, 53, 1) 50%,
                                rgba(38, 44, 53, 0.6) 100%
                            ), url(${bg})`,
                    }}
                >
                    <div className="animated__line-2 smooth"></div>
                    <div className="content py-5">
                        <div className="flex-center-both flex-col pt-20 pb-10 space-y-8">
                            {token ? (
                                <Button
                                    element="Link"
                                    to="/register"
                                    className="bg-gradient-1 rounded-full clr-white smooth font-h1 px-20 py-3 border-0 shadow-sm"
                                    hoverEffect="hover-shadow"
                                >
                                    انضم إلينا الأن !
                                </Button>
                            ) : (
                                <Button
                                    element="Link"
                                    to="/me/user/courses"
                                    className="bg-gradient-1 rounded-full clr-white smooth font-h1 px-20 py-3 border-0 shadow-sm"
                                    hoverEffect="hover-shadow"
                                >
                                    انضم إلينا الأن !
                                </Button>
                            )}
                            <Button
                                element="a"
                                href="https://booxtore.net/"
                                className="bg-rose-500 rounded-full clr-white smooth font-h1 px-20 py-3 border-0 shadow-sm cursor-pointer"
                                hoverEffect="hover-shadow"
                            >
                                شراء المذكرات
                            </Button>
                        </div>
                    </div>
                    <Waves className="fill-primary-container" />
                </div>
            </section>
        </>
    );
};

export default AboutSection;
