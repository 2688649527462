import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import MainSection from "../sections/home/MainSection";
import { ScrollingProvider } from "../context/ScrollingContext";
import { getYearPlaceHolder } from "../services/yearSevices";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";
import AboutSection from "../sections/home/AboutSection";
import Button from "../components/ui/Button";
import AuthContext from "../context/AuthContext";

const Year = () => {
    const { id } = useParams();

    const { token, user } = useContext(AuthContext);

    return (
        <>
            {token ? (
                <div className="py-10 space-y-10">
                    <div className="flex-center-both flex-col space-y-4">
                        <div className="font-w-bold font-bigmain text-center">
                            أهلًا{" "}
                            <span className="before-box relative">
                                <span className="relative">{user.first_name}</span>
                            </span>
                        </div>
                        <div className="font-h1 font-w-bold">
                            مرحبًا بك في منصة{" "}
                            <span className="text-cyan-600 dark:text-cyan-400 smooth">
                                د. محمد أيمن
                            </span>
                        </div>
                        <div className="font-h2">للدخول على اشتراكاتك </div>
                        <Button
                            color="cyan"
                            className="saturate-50 font-h3 font-w-bold px-20 py-2"
                            element="Link"
                            to="/me/user/courses"
                        >
                            إشتراكاتك
                        </Button>
                        <span className="font-w-bold pt-10 font-h2">
                            او تصفح الكورسات للإشتراك بكورس جديد
                        </span>
                    </div>
                    <div className="flex-center-both"></div>
                </div>
            ) : (
                <>
                    <ScrollingProvider>
                        <MainSection title={getYearPlaceHolder(id)} />
                    </ScrollingProvider>
                    <AboutSection />
                </>
            )}

            <CoursesSection title={getYearPlaceHolder(id)} year={id} />
        </>
    );
};

export default Year;
