import React from "react";

import NavbarLink from "../layout/navbar/components/NavbarLink";
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import logo from "../assets/navbar/logo.png";
import CenterIcon from "../components/ui/CenterIcon";

export const navLogoComponent = (darkmode) => (
    <>
        {/* <img
            className="h-12 w-auto block lg:hidden"
            src={logo}
            alt="Workflow"
        /> */}
        <img
            className="h-10 sm:h-14 w-auto"
            // src={darkmode ? logoTextDark : logoTextLight}
            src={logo}
            alt="Workflow"
        />
    </>
);
export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    ` ${!isRTL ? "en" : ""} ${scrolling && "bg-primary-container"}`;
export const NavbarRegisterComponent = () => (
    <NavbarLink
        defualtHover="hover:bg-primary-container hover:text-yellow-500 dark:hover:text-yellow-300 group"
        to="/register"
        className="bg-yellow-500 dark:bg-yellow-300 text-slate-100 dark:text-slate-900 border-2 dark:border-yellow-300 border-yellow-500 group"
    >
        <span className="flex-center-both group-hover:text-yellow-500 dark:group-hover:text-yellow-300 text-slate-100 dark:text-slate-900 smooth ">
            ! انشئ حسابك الآن
        </span>
        <span className="flex-center-both font-h2 smooth dark:text-yellow-500 text-yellow-300 group-hover:text-yellow-500 dark:group-hover:text-yellow-300">
            <CenterIcon icon="clarity:dna-solid" nY="0" />
        </span>
    </NavbarLink>
);
export const NavbarLoginComponent = ({ isRTL }) => (
    <NavbarLink
        to="/login"
        className="border border-cyan-400 border-opacity-0"
        defualtHover="hover:dark:border-opacity-100  hover-shadow"
    >
        <span className="flex-center-both space-x-1">
            <span className="text-cyan-500 dark:text-cyan-300 smooth">الدخول</span>
            <span>تسجيل</span>
        </span>
        <CenterIcon
            icon="uim:stethoscope-alt"
            className="text-cyan-500 dark:text-cyan-300 font-h1 smooth"
            nY="0"
        />
    </NavbarLink>
);
export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        <CenterIcon icon="uim:stethoscope-alt" className="text-yellow-400 font-h1" nY="0" />

        <span className="flex-center-both">الدخول إلى حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        <CenterIcon icon="clarity:dna-solid" className="font-h1 text-cyan-400" nY="0" />
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <span className="flex-center-both">انشئ حسابك الآن !</span>
    </DisclosureLink>
);

export const domainName = "mahmoud-magdy.com";
