import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "ayman-admin";
export const domainName = "mohammed-ayman.com";
export const teachersubadminPath = "ayman-teacher-admin";

export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = true;
export const isCodes = true;
export const isBtns = false;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const adminPanelColor = "yellow";
export const headerSectionColor = "yellow";
export const navbarIconColor = "text-yellow-400";
export const navbarDisclosureColor = "cyan";

export const progressBarColor = "yellow";
export const loadingBarColor = "yellow";

export const isBunny = false;

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = true;
export const prepaidInfoColor = "cyan";

export const autoChangeRandomNames = false;

export const navThemeRTL = false;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "cyan";
export const registerPageColor = "yellow";

export const isAnalytics = false;

export const isAbleChangingPassword = false;

export const isMultiAdmin = true;

export const isSubjectSubscriptionable = false;

export const isAccountCreationRequest = false;

export const showExamStatisticsTable = true;

export const isCategories = true;

export const isOtpEnabled = false;

export const isWalletEnabled = true;

export const isHideNavbarFromAdminPanel = false;

export const isOnlySubscribeFromWallet = false;

export const isCenterUsers = false;

export const isForgetPassword = false;

export const isAdminUsers = false;

export const isSubscribeBySubjectTeacher = false;

export const isMigrationFromInsertAuto = false;
export const isChargeInsertAuto = true;
export const isInsertAutoTypes = true;

export const isCourseRenamedToMonthlySubscriptions = false;

export const isCommunity = false;

export const isCustomUserExamLimit = true;

export const isCenterApi = false;

export const isRemotePlatformIntegrationEnabled = true;
