import React, { useContext, useEffect } from "react";

import MainSection from "../sections/home/MainSection";
import { ScrollingProvider } from "../context/ScrollingContext";

import YearsSection from "../sections/home/YearsSection";

import AboutSection from "../sections/home/AboutSection";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const Home = () => {
    const { token, user } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate(`/years/${user.year}`);
        }
    }, [token]);

    return (
        <>
            <ScrollingProvider>
                <MainSection />
            </ScrollingProvider>
            <AboutSection />

            {/* <CoursesSection /> */}
            <YearsSection />
        </>
    );
};

export default Home;
